<template>
  <Transition>
    <b-row
      class="justify-content-md-center spin-loader-block"
      align-v="center"
    >
      <b-col
        cols="12"
        md="auto"
        class="text-center"
      >
        <div
          class="spin-loader"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </b-col>
    </b-row>
  </Transition>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
}
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.spin-loader-block {
  position:relative;
  top: 200px;
}

.spin-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spin-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #67A6F0;
  border-radius: 50%;
  animation: spin-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #67A6F0 transparent transparent transparent;
}

.spin-loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.spin-loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.spin-loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
